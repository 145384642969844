import { useState, useEffect, useCallback } from "react";

const useScrollTo = (scrollBlock: 'start' | 'center'|'end') => {
    const [serviceElement, setServiceElement] = useState<HTMLElement>();
  const [featureElement, setFeatureElement] = useState<HTMLElement>();
  const [contactElement, setContactElement] = useState<HTMLElement>();

    useEffect(() => {
      const sEl = document.getElementById('services');
      const fEl = document.getElementById('features');
      const cEl = document.getElementById('contactUs');
      if (sEl && fEl && cEl) {
        setServiceElement(sEl);
        setFeatureElement(fEl);
        setContactElement(cEl);
      }
    }, []);

  const scrollToElement = useCallback(
    (linkTo) => {
      switch (linkTo) {
        case 'services':
          if (serviceElement) {
            serviceElement.scrollIntoView({ behavior: 'smooth', block: scrollBlock });
          }
          break;
        case 'features':
          if (featureElement) {
            featureElement.scrollIntoView({ behavior: 'smooth', block: scrollBlock });
          }
          break;
        case 'contact':
          if (contactElement) {
            contactElement.scrollIntoView({ behavior: 'smooth', block: scrollBlock });
          }
          break;
        default:
          return;
      }
    },
    [serviceElement, featureElement, contactElement]
  );

  return{scrollToElement}
};

export default useScrollTo;
