import useScrollTo from '../../../hooks/useScrollTo';

import styles from './DesktopNav.module.scss';


const DesktopNav = () => {
  const {scrollToElement} = useScrollTo('center')

  return (
    <ul className={styles.anchorList}>
        <li aria-label="Services" className={styles.listItem}>
          <a onClick={() => scrollToElement('services')}>Services</a>
        </li>

        <li aria-label="Features" className={styles.listItem}>
          <a onClick={() => scrollToElement('features')}>Features</a>
        </li>

      <li aria-label="Contact" className={styles.listItem}>
        <a onClick={() => scrollToElement('contact')}>Get In Touch</a>
      </li>
    </ul>
  );
};

export default DesktopNav;
