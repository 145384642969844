import { useContext, useEffect } from 'react';
import { ViewportContext, ViewportInfo } from '../providers/ViewportProvider';

type windowSizeTypes = number | boolean | undefined;

type ViewportData = ViewportInfo & {
  isMobile: windowSizeTypes;
  isMobileDevice: windowSizeTypes;
};

const MOBILE_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1024;

const useViewport = (): ViewportData => {
  const { width, height, handleResize } = useContext<ViewportInfo>(ViewportContext);

  const isMobile = width && width < MOBILE_BREAKPOINT;
  const isMobileDevice = width && width < DESKTOP_BREAKPOINT;

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof width === 'undefined') {
      handleResize();
    }
  }, [handleResize, width]);

  return { width, height, handleResize, isMobile, isMobileDevice };
};

export default useViewport;
