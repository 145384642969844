import Image from 'next/image';
import checkMark from '../../assets/icons/check.png';
import styles from './Features.module.scss';

const Features = () => {
  const aboutText =
    'Coffee Fueled is a full stack web and custom software solutions consultancy that is based out of Fort Myers, Florida.We are a team of few, working as full - stack software engineers who find ourselves wanting to bring more experience, growth, and success to those around us.With close to ten years of experience and significantly experienced colleagues in our network - we started this consultancy to do our best in helping our clients accomplish their needs and dreams, but most importantly be the best they can be!';
  return (
    <div id="features" className={styles.featureContainer}>
      <h1 className={styles.title}>
        why <br className={styles.firstBreak} /> choose <br className={styles.secBreak} />
        <span className={styles.companyName}>coffee fueled</span>
      </h1>

      <p className={styles.featuresDesc}>
        We are a close knit team with the experience and knowledge to exceed your expectations of
        pixel perfect and thoroughly tested solutions.
      </p>
      <div className={styles.featureItemContainer}>
        <div className={styles.featureItem}>
          <span className={styles.checkImg}>
            <Image alt="green checkmark" src={checkMark} />
          </span>
          <h2>Fully responsive mobile-first designs</h2>
        </div>
        <div className={styles.featureItem}>
          <span className={styles.checkImg}>
            <Image alt="green checkmark" src={checkMark} />
          </span>
          <h2>Pleasant collaboration experience</h2>
        </div>
        <div className={styles.featureItem}>
          <span className={styles.checkImg}>
            <Image alt="green checkmark" src={checkMark} />
          </span>
          <h2>Speed and search optimization</h2>
        </div>
        <div className={styles.featureItem}>
          <span className={styles.checkImg}>
            <Image alt="green checkmark" src={checkMark} />
          </span>
          <h2>Reliable, experienced, and efficient team based in the USA</h2>
        </div>

        <div className={styles.featureItem}>
          <span className={styles.checkImg}>
            <Image alt="green checkmark" src={checkMark} />
          </span>
          <h2>Quality design with quality assurance</h2>
        </div>

        <div className={styles.featureItem}>
          <span className={styles.checkImg}>
            <Image alt="green checkmark" src={checkMark} />
          </span>
          <h2>Free quotes, consultations, and proposals</h2>
        </div>
      </div>
      <h3 className={styles.pricingText}>*Pricing is flexible and can be hourly or on a per project rate. We are willing to work with all Inquiries! </h3>
    </div>
  );
};

export default Features;
