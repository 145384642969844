import { Button } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import useScrollTo from '../../hooks/useScrollTo';
import styles from './Hero.module.scss';

const Hero = () => {
const { scrollToElement } = useScrollTo('center');

  return (
    <div className={styles.relativeHeroContainer}>
      <div className={styles.heroContainer}>
        <div className={styles.heroContent}>
          <h1 className={styles.title}>
            coffee fueled <br />
            web solutions
          </h1>
          <h2 className={styles.description}>
            High quality & efficient software solutions specializing in custom web development.
            <br /> We’re here to bring your ideas to life!
          </h2>
          <Button
            leftIcon={<ChevronDown className={styles.chevronIcon} size="36" />}
            color="teal"
            size="md"
            uppercase
            className={styles.ctaButton}
            onClick={() => scrollToElement('contact')}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
