import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <h4 className={styles.title}>
        Copyright © 2022 | Coffee Fueled Web Solutions LLC | All Rights Reserved
      </h4>
    </div>
  );
};

export default Footer;
