import Image from 'next/image';

import styles from './Header.module.scss';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import coffeeCup from '../../assets/icons/coffeeCup.png';
import useViewport from '../../hooks/useViewport';

const Header = () => {
  const { isMobile } = useViewport();


  return (
    <>
      <div role="navigation" className={styles.headerContainer}>
        <div className={styles.leftIcon}>
          <Image alt="coffee mug, steam, coffee bean" src={coffeeCup} />
        </div>{isMobile ?
          (isMobile ? <MobileNav /> : <DesktopNav />) : <></>}
      </div>
    </>
  );
};

export default Header;
