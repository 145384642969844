import { useState } from 'react';

import Image from 'next/image';
import styles from './MobileNav.module.scss';
import menuIcon from '../../../assets/icons/menu.png';
import closeMenu from '../../../assets/icons/closeMenu.png';
import useScrollTo from '../../../hooks/useScrollTo';

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { scrollToElement } = useScrollTo('start');

  return (
    <>
      <button
        aria-label="mobile menu button toggle"
        onClick={() => setIsOpen(!isOpen)}
        className={styles.mobileBtn}
      >
        <Image
          alt={`mobile menu icon - ${isOpen ? 'close' : 'open'}`}
          src={isOpen ? closeMenu : menuIcon}
        />
      </button>
      {isOpen && (
        <div className={styles.mobileMenu}>
          <ul className={styles.mobileAnchorList}>
            <li aria-label="Services" className={styles.mobileListItem}>
              <a onClick={() => {
                scrollToElement('services')
                setIsOpen(false)
              }}>Services</a>
            </li>

            <li aria-label="Features" className={styles.mobileListItem}>
              <a onClick={() => {scrollToElement('features') 
              setIsOpen(false)}}>Features</a>
            </li>

            <li aria-label="Contact" className={styles.mobileListItem}>
              <a onClick={() => {scrollToElement('contact')
            setIsOpen(false);}}>Get In Touch</a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default MobileNav;
