import { useState } from 'react';
import { useForm } from '@mantine/form';
import { TextInput, Button, Group, Textarea } from '@mantine/core';

import styles from './Contact.module.scss';
import { showNotification } from '@mantine/notifications';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      subject: (value) => (value.length < 3 ? 'Please do not leave this field empty' : null),
      message: (value) => (value.length < 3 ? 'Please do not leave this field empty' : null),
    },
    validateInputOnBlur: true,
  });

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const submitForm = async () => {
    setIsLoading(true);
    await sleep(2000);
    const data = {
      ...form.values,
    };
    const resp = await fetch('/api/contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (resp.status === 200) {
      showNotification({
        title: 'Success!',
        message: 'We`ll touch base with you shortly',
        autoClose: 6000,
        color: 'green',
      });
    } else {
      showNotification({
        title: 'Error 🙁',
        message: 'There was an issue submitting your inquiry',
        autoClose: 6000,
        color: 'red',
      });
      console.log('');
    }
    setIsLoading(false);
    form.reset();
  };

  return (
    <div id="contactUs" className={styles.contactContainer}>
      <div className={styles.contactBody}>
        <div className={styles.contactText}>
          <h1 className={styles.title}>Contact Us</h1>
          <h2>Thanks for your interest!</h2>
          <h3>Please fill out the form below and we'll get back to you shortly!</h3>

          <h4 className={styles.infoEmail}>
            <br />
            Although the form provided is the quickest way to get in touch with us - you may also
            drop us a line at:{' '}
            <a href="mailto:chris@coffeefueledwebsolutions.com">
              <em className={styles.email}>chris@coffeefueledwebsolutions.com</em>
            </a>
          </h4>
        </div>

        <div id="contactForm" className={styles.contactForm}>
          <TextInput
            aria-label="Name"
            radius="md"
            size="md"
            label="Name"
            placeholder="Name"
            {...form.getInputProps('name')}
          />
          <TextInput
            aria-label="Email"
            radius="md"
            size="md"
            label="Email"
            placeholder="Email"
            {...form.getInputProps('email')}
          />
          <TextInput
            aria-label="Subject"
            radius="md"
            size="md"
            label="Subject"
            placeholder="Subject"
            {...form.getInputProps('subject')}
          />
          <Textarea
            autosize
            aria-label="Message"
            radius="md"
            size="md"
            label="Message"
            placeholder="Message"
            {...form.getInputProps('message')}
          />

          <Group position="center" mt="xl">
            <Button
              className={styles.submitButton}
              type="submit"
              size="md"
              radius="md"
              variant="outline"
              value="send"
              disabled={!form.isValid()}
              loading={isLoading}
              onClick={submitForm}
            >
              submit
            </Button>
          </Group>
        </div>
        <h4 className={styles.infoEmailMobile}>
          <br />
          Although the form provided is the quickest way to get in touch with us - you may also drop
          us a line at:{' '}
          <a href="mailto:chris@coffeefueledwebsolutions.com">
            <i className={styles.emailMobile}>chris@coffeefueledwebsolutions.com</i>
          </a>
        </h4>
      </div>
    </div>
  );
};

export default Contact;
