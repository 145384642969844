import { Accordion } from '@mantine/core';

import styles from './Services.module.scss';

const Services = () => {
  const serviceCardContent = [
    {
      title: 'Website development',
      description:
        'Significant experience ranging from landing pages, multi-page sites, and custom solutions that integrate with various tools or APIs.',
    },
    {
      title: 'Mobile App Development',
      description: 'Sleek and modern Android/IOS app development using the latest technologies',
    },
    {
      title: 'Hosting & Consulting',
      description:
        'We’ll answer any questions you may have or do our best to inform you of the best way to go about or address your concerns! We’re even able to jump into the team and help speed up your development process!',
    },
    {
      title: 'Custom software solutions',
      description:
        'With Experience across the board we can work together to plan, define, and implement a software solution that is right for you. Whether that may be a website, bot, script, API, a custom integration tool, or anything else under the sun!',
    },
  ];

  const defaultVal = serviceCardContent.map((d) => d.title);

  return (
    <div id="services" className={styles.servicesContainer}>
      <h1 className={styles.title}>Services</h1>
      <div className={styles.servicesCardContainer}>
        <Accordion
          className={styles.accordion}
          variant="separated"
          radius="md"
          defaultValue={defaultVal}
          multiple={true}
        >
          <Accordion.Item value={serviceCardContent[0].title}>
            <Accordion.Control
              aria-label={serviceCardContent[0].title}
              className={styles.accordionHeading}
            >
              {serviceCardContent[0].title}
            </Accordion.Control>
            <Accordion.Panel className={styles.accordionPanelText}>
              {serviceCardContent[0].description}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value={serviceCardContent[1].title}>
            <Accordion.Control
              aria-label={serviceCardContent[1].title}
              className={styles.accordionHeading}
            >
              {serviceCardContent[1].title}
            </Accordion.Control>
            <Accordion.Panel className={styles.accordionPanelText}>
              {serviceCardContent[1].description}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value={serviceCardContent[2].title}>
            <Accordion.Control
              aria-label={serviceCardContent[2].title}
              className={styles.accordionHeading}
            >
              {serviceCardContent[2].title}
            </Accordion.Control>
            <Accordion.Panel className={styles.accordionPanelText}>
              {serviceCardContent[2].description}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value={serviceCardContent[3].title}>
            <Accordion.Control
              aria-label={serviceCardContent[3].title}
              className={styles.accordionHeading}
            >
              {serviceCardContent[3].title}
            </Accordion.Control>
            <Accordion.Panel className={styles.accordionPanelText}>
              {serviceCardContent[3].description}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Services;
